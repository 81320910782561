


























import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import app from '@/store/modules/app'

@Component({
  components: { Main },
  metaInfo() {
    return {
      title: `${app.title} | Registry`,
    }
  },
})
export default class Registry extends Vue {
  mounted(): void {
    const script = document.createElement('script')
    script.src = 'https://widget.zola.com/js/widget.js'
    script.async = true
    script.id = 'zola-wjs'
    document.body.appendChild(script)
  }
}
